@import './constants';

body {
  color: $black;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  /**
  * HAMBURGER MENU STYLES
  */
  .app {
    transition: margin-left 200ms;

    &::after {
      content: '';
      transition: margin-left 200ms, background 200ms;
    }

    &.hamburger-open {
      margin-left: -$hamburger-menu-width;
    
      &::after {
        background: rgba(0, 0, 0, 0.25);
        content: '';
        height: 100vh;
        left: 0;
        margin-left: -$hamburger-menu-width;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 1000;
      }
    }
  }
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  
* {
  margin: 0;
  padding: 0;
}

a {
  color: $pink;
}

.cursive {
  font-family: 'Dancing Script', cursive;
}

.image-border {
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.2);
  line-height: 0;
  padding: 5px;
}
