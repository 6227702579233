@import './constants';

#blog {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  
  .featured {
    display: flex;
    justify-content: center;
    margin-top: $content-top-margin;
    max-width: $content-max-width;
    width: $content-width;

    .blog-preview {
      max-width: 500px;

      .image-container {
        min-height: 300px;
      }
    }
  }

  .container {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: 50% 50%;
    margin-top: $content-mobile-top-margin;
    max-width: $content-max-width;
    width: $content-width;
  }

  @media only screen and (max-width: 570px) {
    .featured {
      margin-top: $content-mobile-top-margin;
    }

    .container {
      grid-template-columns: auto;

      .content {
        align-items: center;
        display: block;
        text-align: center;
      }
    }
  }
}
