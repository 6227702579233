@import './constants';

#recipe-post {
  display: flex;
  justify-content: center;
  
  .container {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin-top: $content-top-margin;
    max-width: $content-max-width;
    width: $content-width;

    .title {
      color: $pink;
      text-align: center;
    }
    
    .image-container {
      background-position: center 15%;
      background-size: cover;
      border-radius: 10px;
      height: 400px;
      margin: 60px 0;
      width: 100%;
    }

    .recipe-content {
      max-width: 750px;

      .description {
        color: $gray;
        line-height: 25px;
        margin: 20px 0;
      }

      h2 {
        margin: 20px 0 10px;
      }

      .split {
        display: grid;
        grid-template-columns: auto max-content;
      }
      
      .time {
        margin-top: calc(20px - 10px);

        div {
          margin: 10px 0;
        }
      }

      .ingredients {
        .item {
          align-items: center;
          display: flex;
          margin: 16px 0;

          .dot {
            background: $pink;
            border-radius: 10px;
            min-height: 10px;
            min-width: 10px;
          }

          p {
            cursor: pointer;
            margin-left: 15px;
          }
        }
      }

      .directions {
        .item {
          display: flex;
          margin: 28px 0;

          .num {
            font-size: 32px;
            line-height: 32px;
            min-width: 18px;
            text-align: right;

            &.long {
              min-width: 37px; 
            }
          }

          .text-container {
            display: flex;
            height: min-content;
            margin-left: 10px;
            position: relative;

            .separator {
              background: $pink;
              border-radius: 10px;
              margin: 0 12px;
              min-width: 3px;
            }
          }

          .text {
            cursor: pointer;
            height: min-content;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 570px) {
    .container {
      .recipe-content {
        .split {
          display: flex;
          flex-flow: column-reverse;
          
          .time {
            column-gap: 32px;
            display: grid;
            grid-template-columns: auto auto auto;
            justify-content: left;
          }
        }
      }
    }
  }
}
