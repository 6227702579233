@import './constants';

#home {
  display: flex;
  justify-content: center;
  
  .container {
    margin-top: $content-top-margin;
    max-width: $content-max-width;
    width: $content-width;
    
    .content {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .image-border {
        max-width: 320px;
        width: 35vw;

        img {
          max-width: 320px;
          width: 35vw;
        }
      }

      .about {
        margin-left: 50px;
        width: 100%;

        h1 {
          font-size: 3em;
          margin-bottom: 8px;
        }
        
        p {
          color: $gray;
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .container {
      margin-top: $content-mobile-top-margin;

      .content {
        align-items: center;
        display: block;
        text-align: center;
        
        .image-border {
          display: inline-block;
          max-width: 300px;
          width: $content-width;

          img {
            max-width: 300px;
            width: $content-width;
          }
        }

        .about {
          margin: calc(#{$content-top-margin} / 2) 0 0;
          text-align: left;
        }
      }
    }
  }
}
