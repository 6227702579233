@import './constants.scss';

#navbar {  
  align-items: center;
  background: linear-gradient(170deg, $pink, $off-pink, $pink);
  box-shadow: 0 0 4px rgba($color: #000, $alpha: 0.1);
  display: flex;
  height: 70px;
  justify-content: center;
  width: 100vw;

  .container {    
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    max-width: 1200px;
    width: $content-width;
  }
  
  .cursive {
    color: #fff;
    font-size: 2.8em;
    text-decoration: none;
  }

  #nav-items {
    display: flex;
    height: 100%;
    list-style: none;

    a {
      text-decoration: none;
    }
    
    
    .item {
      align-items: center;
      color: $light-pink;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 0 20px;
      position: relative;
      transition: color 150ms;

      .underline {
        background: white;
        bottom: 0;
        height: 0;
        position: absolute;
        width: calc(100% - 40px);
      }

      &:hover {
        color: white;
      }

      &.active {
        color: white;

        .underline {
          height: 2px;
        }
      }
    }
  }

  #hamburger {
    background: linear-gradient(0, $pink -90%, $off-pink 200%);
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    padding: 2px 5px;
    position: fixed;
    right: 30px;
    transition: background 150ms;
    z-index: 10;

    &.open {
      background: linear-gradient(0, rgba($pink, 0) -90%, rgba($off-pink, 0) 200%);
    }

    .line {
      background: white;
      border-radius: 10px;
      height: 3px;
      margin: 7px 0;
      transition: all 200ms;
      width: 30px;

      &.open {
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-15px);
          width: 0;
        } 

        &:nth-child(1) {
          transform: translateY(10px) rotate(-45deg);
        }
    
        &:nth-child(3) {
          transform: translateY(-10px) rotate(-135deg);
        } 
      }
    }
  }

  #hamburger-menu {
    background: linear-gradient(275deg, $pink, $off-pink);
    // background: $pink;
    box-sizing: border-box;
    clip: rect(0, $hamburger-menu-width, 100vh, $hamburger-menu-width);
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    transition: clip 200ms;
    width: $hamburger-menu-width;

    &.open {
      clip: rect(0, $hamburger-menu-width, 100vh, 0);
    }

    &.closed {
      opacity: 0;
    }

    #nav-items {
      align-items: flex-end;
      color: white;
      flex-flow: column;
      height: 200px;
      margin-top: 100px;
      padding: 0 30px;
      position: relative;

      a {
        width: 100%;
        
        li {
          height: calc(200px / 4);
          justify-content: flex-end;
          
          span {
            position: relative;

            .underline {
              bottom: -6px;
              width: 100%;
            }
          }

          &.active {
            color: white;
          }

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}
