@import './constants.scss';

.recipe-preview {
  a {
    color: inherit;
    display: flex;
    text-decoration: none;

    .image-border {
      margin-right: 25px;
      min-width: 45%;

      .no-overflow {
        overflow: hidden;

        .image-container {
          background-position: center center;
          background-size: cover;
          height: 30vw;
          max-height: 250px;
          min-height: 200px;
          overflow: hidden;
          transition: transform 500ms;
        }
      }
    }

    .info {
      display: flex;
      flex-flow: column;

      .title-preview {
        h2 {
          margin: 10px 0;
        }
    
        p {
          color: $gray;
          font-style: normal;
        }
      }

      .time {
        display: flex;
        margin-top: 15px;
      }
    }
  }
}

.recipe-preview:hover {
  .image-border {
    .no-overflow {
      .image-container {
        transform: scale(1.1);
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .recipe-preview {
    a {
      flex-flow: column;
    }
  }
}
