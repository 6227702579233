$black: #333;
$pink: #f58389;
$off-pink: #f59ea2;
$light-pink: #ffd7d7;
$gray: #767676;

$content-width: calc(100vw - 60px);
$content-max-width: 850px;
$content-top-margin: 80px;
$content-mobile-top-margin: 50px;

$hamburger-menu-width: 190px;
