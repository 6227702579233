@import './constants';

#blog-post {
  display: flex;
  justify-content: center;
  
  .container {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin-top: $content-top-margin;
    max-width: $content-max-width;
    width: $content-width;

    .date {
      color: $gray;
      margin-bottom: 10px;
    }

    .title {
      color: $pink;
      text-align: center;
    }
    
    .image-container {
      background-position: center 15%;
      background-size: cover;
      border-radius: 10px;
      height: 400px;
      margin: 60px 0;
      width: 100%;
    }

    .blog-content {
      max-width: 750px;

      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $black;
      }

      p {
        color: $gray;
        line-height: 25px;
        margin: 20px 0;
      }

      img {
        border-radius: 10px;
        margin: 20px 0;
        width: 100%;
      }

      ol {
        list-style-position: inside;
      }

      .image-column {
        align-items: center;
        display: flex;
        justify-content: center;

        img {
          margin-right: 10px;
          margin-top: 0;
          max-width: calc(33% - 10px);
          width: auto;

          &:last-child {
            margin-right: 0;
            max-width: 33%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 570px) {
    .container {
      .blog-content {
        .image-column {
          flex-flow: column;

          img {
            margin-right: 0;
            max-width: 75%;
            
            &:last-child {
              max-width: 75%;
            }
          }
        }
      }
    }
  }
}
